/*-----------------
	38. Loader
-----------------------*/

div#global-loader {
    background: $__white;
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100vh;
	@extend %flex-align-center;
	top: 0;
}
.loader-img {
	position: absolute;
    z-index: 9;
    -webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
	0% { 
		-webkit-transform: rotate(0deg); 
	}
	100% { 
		-webkit-transform: rotate(360deg); 
	}
}

@keyframes spin {
	0% { 
		transform: rotate(0deg);
	}
	100% { 
		transform: rotate(360deg); 
	}
}