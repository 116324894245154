/*
Author       : Dreamguys
Template Name: DreamSports - Bootstrap Template
Version      : 1.0
*/

/*============================
 [Table of CSS]

1. Typography
2. General
3. Grid
4. Buttons
5. Table
6. Header
7. Footer
8. Breadcrumb
9. Forms
10. Select
11. Modal
12. Datetimepicker
13. Home
14. Common
15. Book Court
16. Book Coach
17. Venue Details
18. Coach Detail
19. Listing
20. Listing Filter
21. Sidebar
22. About Us
23. Our Team
24. Services
25. Gallery
26. Events
27. Testimonials
28. Blog
29. Contact
30. Authentication
31. Dashboard
32. Chat
33. Invoice
34. Wallet
35. user
36. Coach Profile
37. Error Pages
38. Loader
39. Color
40. Add Court

========================================*/

// UTILS 
@import "utils/variables";
@import "utils/mixins";

// BASE 
@import "base/typography";
@import "base/colors"; 
@import "base/base";
@import "base/grid";

// COMPONENTS 
@import "components/button";
@import "components/table";

// LAYOUT
@import "layout/content";
@import "layout/header";
@import "layout/footer";
@import "layout/breadcrumb";
@import "layout/forms";
@import "layout/select2";
@import "layout/modal";
@import "layout/datetimepicker";
@import "layout/loader";

// PAGES
@import "pages/home";
// @import "pages/common";
// @import "pages/book-court";
// @import "pages/book-coach";
// @import "pages/venue-details";
@import "pages/coach-detail";
// @import "pages/listing";
// @import "pages/listing-filter";
@import "pages/sidebar";
@import "pages/about-us";
// @import "pages/our-team";
// @import "pages/services";
@import "pages/gallery";
@import "pages/events";
@import "pages/testimonials";
// @import "pages/blog";
@import "pages/contact";
// @import "pages/authentication";
// @import "pages/dashboard";
// @import "pages/chat";
// @import "pages/invoice";
// @import "pages/wallet";
@import "pages/user";
@import "pages/coach-profile";
// @import "pages/error-terms";
// @import "pages/add-court";

.court-wrapper{
    align-items: center;
}

@media screen and (max-width: 980px) {
    .court-wrapper{
        display: flex !important;
        flex-direction: column !important;
        text-align: center;
    }

    .court-wrapper .swiper-wrapper{
        height: 500px;
    }

    .court-wrapper .swiper-wrapper div span{
        width: 100%;
        padding: 10px 0 !important;
    }
}